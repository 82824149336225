import { ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';
import { depositsRoutes } from '@/app/router/deposits.ts';
import { additionalRoutes } from './additional';
import { currencyRoutes } from './currency';
import { documentsRoutes } from './documents';
import { inquiriesRoutes } from './inquiries';
import { onboardingRoutes } from './onboarding';
import { settingsRoutes } from './settings';

const routes = [
  {
    path: '/',
    component: () => import('@/app/layouts/RootLayout.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/app/guards/auth/AuthGuard.vue'),
        children: [
          {
            path: '/',
            component: () => import('@/app/layouts/default-layout'),
            children: [
              {
                path: '/',
                name: 'main',
                meta: {
                  keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
                },
                component: () => import('@/pages/MainPage.vue')
              },
              {
                path: '/all-services',
                name: 'all-services',
                meta: {
                  keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
                },
                component: () => import('@/pages/all-services/IndexPage.vue')
              },
              ...documentsRoutes,
              ...additionalRoutes,
              ...settingsRoutes,
              ...inquiriesRoutes,
              {
                path: '/statements',
                name: 'statements',
                meta: {
                  keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
                },
                component: () =>
                  import(
                    '@/pages/statements/StatementsPage.vue'
                  )
              },
              ...currencyRoutes,
              ...depositsRoutes,
              ...onboardingRoutes
            ]
          }
        ]
      }
    ]
  },

  // Always leave this as last one,
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/app/layouts/RootLayout.vue'),
    children: [
      {
        path: '/:catchAll(.*)*',
        component: () => import('@/app/guards/auth/AuthGuard.vue'),
        children: [
          {
            path: '/:catchAll(.*)*',
            name: 'not-found',
            component: () => import('@/app/layouts/default-layout')
          }
        ]
      }
    ]
  }
];

export default routes;
