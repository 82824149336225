import axios, { CreateAxiosDefaults, CustomParamsSerializer } from 'axios';
import * as Qs from 'qs';
import useApiErrorResponseInterceptor from '../api/interceptor/useApiErrorResponseInterceptor';
import useApiSuccessRequestInterceptor from '../api/interceptor/useApiSuccessRequestInterceptor';
import useApiSuccessResponseInterceptor from '../api/interceptor/useApiSuccessResponseInterceptor';
import { removeNullFields } from '../utils';

const fallbackApiBaseUrl = 'https://kbdbo-web-api-dev.kapitalbank.uz'; // TODO

const config: CreateAxiosDefaults = {
  baseURL: import.meta.env.VITE_API_BASE_URL || fallbackApiBaseUrl,
  timeout: 60000,
  paramsSerializer: {
    serialize: ((params: Record<string, unknown>) => {
      return Qs.stringify(removeNullFields(params), { arrayFormat: 'repeat' });
    }) as CustomParamsSerializer
  }
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(useApiSuccessRequestInterceptor);

axiosInstance.interceptors.response.use(
  useApiSuccessResponseInterceptor,
  useApiErrorResponseInterceptor
);

export default axiosInstance;
